.login {
  background-image: url("../../images/login-bg.jpg");
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: cover;
  background-position: center;
  height: 100vh;
  justify-content: center;
  align-items: flex-end;

  &__card {
    height: 460px;
    width: 576px;
    background: $lightGrey200;
    border-radius: 12px;
    margin-right: 80px;

    &--header {
      display: flex;
      align-items: center;
      background: #fff;
      height: 100px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding-left: 80px;

      &__logo {
        height: 4em;
      }
    }

    &--body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 45px;
    }

    &--form {
      width: 400px;

      &__row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 25px;
      }

      &__forgot {
        color: $lightBlue300;
        font-size: 1rem;
      }
    }
  }

  &__input {
    height: 50px !important;

    &--icon {
      height: 50px !important;
    }
  }
}
