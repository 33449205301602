.location-modal {
	&__row {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
	}
	&__title {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		letter-spacing: 1.2px;
		font-size: 14px;
		text-transform: uppercase;
		color: $titleLightBlue;
		margin: 10px 0;
	}
	&__subtitle {
		display: flex;
		color: $darkBlue200;
		font-size: 16px;
	}
	&__form {
		&__container {
			display: flex;
			flex-direction: column;
			width: 70%;
		}
		&__detail-row {
			margin-bottom: 1rem;
		}
	}
}
