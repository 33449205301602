.dashboard {
  &__card {
    display: flex !important;
    justify-content: center;
  }

  &__cards {
    height: 100%;

    &--title {
      color: $titleLightBlue;
      font-size: 14px;
      margin-left: 40px;
      padding: 10px 0;

      &:last-of-type {
        margin-left: 1rem;
      }
    }

    &--container {
      padding-bottom: 0.5rem;

      &--title {
        display: flex;
        height: 55px;
        align-items: center;
      }

      @media (max-width: $mobileThreshold) {
        > .columns {
          display: flex;
          flex-wrap: wrap;
          height: auto;
          gap: 10px;
        }
      }
    }

    &--button {
      margin-right: 0.5rem;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__portfolio {
    padding-top: 1.4rem;
  }
}

.sol-dashboard {
  @extend .dashboard;

  &__cards {
    @extend .dashboard__cards;

    &--title {
      @extend .dashboard__cards--title;
      color: $solLightBlack;
    }
  }
}

.label-wrapper {
  width: 100px;
  height: 100px;

  &--l {
    padding-left: 5px;
    width: 350px;
    height: 70px;
  }
}
