.header {
  display: flex;
  background-color: #fff;
  min-height: 70px;
  align-items: center;

  &__title {
    font-size: 1.5rem;
    line-height: 1.25;
    color: #fff;
    text-transform: uppercase;
  }

  &__logo {
    height: 2.2em;
    padding: 0 35px;
  }

  &__organization-picker {
    width: 9rem;
    flex: 0 1 auto;
    margin-right: 2em;
  }

  &__language-picker {
    width: 3.5em;
    flex: 0 1 auto;
    @media (max-width: $mobileThreshold) {
      width: 100%;
    }
  }

  &__profile-text {
    font-family: 'MiriamLibre';
    font-weight: 500;
    text-transform: uppercase;
    color: $iisyBlue;
    letter-spacing: -0.3px;
  }

  &__align {
    align-items: center !important;
  }

  &__mainContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &__settings {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      &__select {
        display: flex;
        margin-bottom: 15px;
        @media (max-width: $mobileThreshold) {
          flex-direction: column;
          margin-bottom: 0;
        }
      }
    }
  }

  &__searchbar {
    display: flex;
    width: auto;
    min-width: 400px;
  }

  &__padding {
    padding-right: 32px;
  }
}
