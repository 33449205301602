/*
 * COLORS
*/

$darkGrey: #a6a6a6;
$darkGrey500: #616161;
$darkText: #383838;

$lightGrey: #d1d1d7;
$lightGrey200: #f0f2f5;
$lightBlue300: #636789;
$darkBlue: #00195f;
$darkBlue200: #050c42;
$darkBlue300: #232d6e;
$darkBlue400: #171a2b;
$blueGrey: #f5f5f8;

$iisyBorder: #80889c69;
$iisyBlue: #00195f;
$iisyYellow: #f9ff5a;
$iisyGreen: #5bf7bf;
$iisyRed: #ff5a73;
$iisyPurple: #c599f1;

$niceGreen: #2e986f;
$niceYellow: #ffcd26;
$niceRed: #d63737;
$locationOrange: #e3893c;
$premiseBlue: #586ce9;
$niceBlue: $darkBlue;
$titleLightBlue: #61668b;
$titleDarkBlue: $darkBlue200;

$headerBG: $darkBlue;
$footerBG: $blueGrey;
$inputBorder: $lightGrey;
$disabledGrey: $darkGrey;

/* Sol Partner */
$solDarkYellow: #ffc72c;
$solMediumYellow: #ffe9aa;
$solLightYellow: #fcebc2;
$solDarkRed: #e4002b;
$solMaroonRed: #bb0067;
$solMenthol: #005f67;
$solToffee: #e1c4a8;
$solLightGreen: #b1cfca;
$solCalch: #bbbbbb;
$solOrange: #cb9500;
$solLightBlack: #262122;


$mobileThreshold: '1250px';
$largeScreenThreshold: '1920px';
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./../../fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'), url(./../../fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'), url(./../../fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: local('Poppins'), url(./../../fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'MiriamLibre';
  font-weight: 500;
  src: local('MiriamLibre'), url(./../../fonts/MiriamLibre-Bold.ttf) format('truetype');
}

