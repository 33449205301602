.list-row {
	&__icon-in-progress {
		color: #ffcd26;
		font-size: 0.9rem;
		margin-right: 0.3rem;
	}
	&__icon-fixed {
		color: #2e986f;
		font-size: 0.9rem;
		margin-right: 0.3rem;
	}
	&__icon-cancelled {
		color: #ff5a73;
		font-size: 0.9rem;
		margin-right: 0.3rem;
	}
	&__icon-open {
		color: #61668b;
		font-size: 0.9rem;
		margin-right: 0.3rem;
	}
}

.bold-text {
	width: calc(100% - 12px);
	height: 19px;
	position: relative;
	overflow: hidden;

	> span {
		color: $iisyBlue;
		font-weight: 700;
		position: absolute;
		white-space: nowrap;
	}

	&--scroll {
		transform: translateX(0);
		transition: 1.5s;
	}

	&:hover .bold-text--scroll {
		transform: translateX(calc(250px - 100%));
	}
}

.sol-bold-text {
	@extend .bold-text;

	> span {
		color: $solLightBlack;
	}
}

.row-text {
	color: $iisyBlue;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row-text.overflow:hover {
	overflow-x: visible;
	white-space: normal;
}
