.navigation-container {
	grid-area: nav;
	width: 300px;
	height: 100%;

	&__header {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 50px;

		&__title {
			color: $iisyBlue;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1.1px;
			font-size: 16px;
		}
	}

	&__list {
		grid-area: nav;
		display: flex;
		flex-direction: column;
		width: 300px;

		height: calc(100vh - #{'110px'});
		border-radius: 10px;
		border: 1px solid $iisyBorder;
		background: #fff;

		&--full-height {
			height: auto;
		}

		&__title {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;

			min-height: 40px;
			margin-top: 5px;
			padding-left: 16px;
			padding-right: 16px;

			&__left {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			&__right {
				align-self: center;
			}
		}

		&--half {
			flex: 1 0 0;
		}
	}
}

.content-container {
	grid-area: content;
	display: grid;
	gap: 20px;
	grid-template-columns: 1fr;

	grid-template-rows: auto minmax(0, 1fr);
	grid-template-areas:
		'tabs'
		'view';
}

.location-container {
	grid-area: view;
	display: grid;
	flex: 1;
	gap: 20px;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto minmax(0, 1fr);
	grid-template-areas:
		'details details'
		'graph list';
	@media (max-width: $mobileThreshold) {
		grid-template-columns: 1fr;
		grid-template-areas:
			'details'
			'graph'
			'list';
	}
}

.data-container {
	grid-area: view;
	display: grid;
	flex: 1;
	gap: 20px;
	grid-template-columns: 200px minmax(0, 1fr);
	grid-template-rows: auto;
	grid-template-areas: 'controls graph';
	@media (max-width: $mobileThreshold) {
		grid-template-columns: 1fr;
		grid-template-areas: 'graph';
	}
}

.graph-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		width: 100%;
		padding: 20px 25px;
		display: flex;
		flex-direction: column;

		> h3 {
			font-size: 18px;
			font-weight: 500;
			color: $iisyBlue;
		}

		> h4 {
			font-size: 12px;
			color: $lightBlue300;
			text-transform: uppercase;
			letter-spacing: 0.8px;
			margin-top: 5px;
		}
	}

	&__buttonbar {
		display: flex;
		justify-content: space-around;
		width: 70%;
		margin-top: 50px;
		margin-bottom: 20px;
	}
}

.location-card {
	grid-area: details;
	border-radius: 10px;
	background: #fff;
	border: 1px solid $iisyBorder;
}

.graph-card {
	grid-area: graph;
	border-radius: 10px;
	background: #fff;
	border: 1px solid $iisyBorder;
}

.list-card {
	margin-top: 25px;
	grid-area: list;
	background: transparent;
	min-width: 0;
}

.freesi-icon {
	display: flex;
	justify-content: flex-end;
	align-items: baseline;

	svg {
		height: inherit;
		width: inherit;
	}
}

.locations-icon {
	margin-right: 0.3em;
	background-repeat: no-repeat;
	background-origin: content-box;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		color: var(--brand-icon-locations);
		height: 1rem;
		width: 1rem;
	}
}

.premises-icon {
	margin-right: 0.3em;
	background-repeat: no-repeat;
	background-origin: content-box;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		color: var(--brand-icon-premises);
		height: 1rem;
		width: 1rem;
	}
}
