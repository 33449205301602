.score {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 10px;

	&__column {
		display: flex;
		flex-direction: column;
		padding-left: 15px;
	}
	&__points-title {
		padding-top: 5px;
		font-size: 11px;
		color: $iisyBlue;
		text-transform: uppercase;
		letter-spacing: 0.5px;
	}
	&__change-title {
		padding-top: 2px;
		font-size: 9px;
		color: $iisyBlue;
		letter-spacing: 0.5px;
	}
	&__circle {
		&--inner {
			width: 33px;
			height: 33px;
			border-radius: 50%;
			background: $iisyBlue;
			display: flex;
			align-items: center;
			justify-content: center;
			&__value {
				color: #fff;
				font-weight: 500;
				font-family: 'MiriamLibre';
				font-size: 13px;
			}
		}
	}
	&__gradients {
		&--green-yellow {
			background: rgb(246, 250, 114);
			background: linear-gradient(270deg, rgba(246, 250, 114, 1) 0%, rgba(38, 245, 168, 1) 100%);
		}
		&--green-green {
			background: rgb(173, 248, 220);
			background: linear-gradient(270deg, rgba(173, 248, 220, 1) 0%, rgba(38, 245, 168, 1) 100%);
		}
		&--yellow-red {
			background: rgb(252, 92, 117);
			background: linear-gradient(270deg, rgba(252, 92, 117, 1) 0%, rgba(246, 250, 114, 1) 100%);
		}
		&--red {
			background: rgb(208, 24, 53);
			background: linear-gradient(270deg, rgba(208, 24, 53, 1) 0%, rgba(255, 90, 115, 1) 100%);
		}
	}
}

.sol-score {
	@extend .score;

	&__points-title {
		@extend .sol-score__points-title;
		color: $solLightBlack;
	}

	&__change-title {
		@extend .sol-score__change-title;
		color: $solMenthol;
	}
}

.user-score {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: -4px;

	&__column {
		display: flex;
		flex-direction: column;
		padding-left: 10px;
		margin-bottom: 20px;
	}

	&__points-title {
		padding-top: 5px;
		font-size: 11px;
		color: $iisyBlue;
		text-transform: uppercase;
		letter-spacing: 0.5px;
	}

	&__change-title {
		padding-top: 2px;
		font-size: 9px;
		color: $iisyBlue;
		letter-spacing: 0.5px;
	}

	&__value {
		text-align: center;
		font-weight: 600;
		font-size: 11px;
		color: #00195f;
	}
}
