.modal {
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 30px;
		height: 80px;
		padding: 20px 40px;
		&__container {
			display: flex;
			align-items: center;
		}
		&__title {
			font-size: 16px;
			text-transform: uppercase;
			letter-spacing: 1.2px;
			color: $titleLightBlue;

			&--notification {
				@extend .modal-header__title;
				font-size: 22px;
			}
		}
	}
	&-body {
		height: 320px;
		&__info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 120px;
			background-color: #f5f5f9;
			padding: 13px 40px;
			&__title {
				font-size: 20px;
				color: $darkBlue200;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&--row {
				display: flex;
				flex-direction: row;
			}
			&--column {
				width: 255px;
				margin-right: 60px;
				&--small {
					display: flex;
					align-items: center;
					margin-right: 50px;
				}
			}
			&--status {
				background-color: $premiseBlue;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				margin-right: 5px;
			}
			&--status-text {
				font-size: 14px;
				letter-spacing: 0.6px;
				color: $darkBlue400;
			}
		}
		&__content {
			padding: 25px 40px;
		}
	}
	&-footer {
		border-bottom-left-radius: 14px;
		border-bottom-right-radius: 14px;
		background-color: #f5f5f9;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100px;

		&__text-area {
			width: 400px;
			margin-left: 40px;
			> h3 {
				font-size: 16px;
				color: $darkBlue400;
			}
			> h4 {
				font-size: 12px;
				text-transform: uppercase;
				color: $titleLightBlue;
				letter-spacing: 1.2px;
			}
		}

		&__button-area {
			margin-right: 40px;
		}

		&--centered {
			@extend .modal-footer;
			justify-content: center;
		}
	}
}

.sol-modal {
	@extend .modal;

	&-header {
		@extend .modal-header;

		&__title {
			@extend .modal-header__title;
			color: $solLightBlack;

			&--notification {
				@extend .modal-header__title--notification;
			}
		}
	}

	&-footer {
		@extend .modal-footer;
		background-color: rgba(187, 187, 187, 0.4);

		&__text-area {
			@extend .modal-footer__text-area;

			> h3 {
				color: $solLightBlack;
			}

			> h4 {
				color: $solLightBlack;
			}
		}

		&--centered {
			@extend .modal-footer--centered;
		}
	}
}
