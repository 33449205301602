.analysis {
	&-container-nodata {
		border-radius: 10px;
		background-color: #fff;
		padding: 30px;
		max-height: 87vh;
	}
	&-container {
		max-height: 87vh;
	}
	&__print-container {
		display: flex;
		height: 33px !important;
		justify-content: flex-end;
		&__comparison-title {
			display: flex;
			align-items: center;
			color: $titleLightBlue;
			margin-right: 10px;
		}
	}
	&__title {
		font-size: 22px;
		color: $darkBlue200;
	}
	&__list {
		display: flex;
		flex-direction: column;
		margin-top: 15px;
		&--row {
			display: flex;
			align-items: center;
			padding-left: 15px;
			height: 40px;
			border-radius: 5px;
			border: 1px solid #c1c1c1;
			margin-bottom: 5px;
			transition: background-color 0.2s linear;

			&:hover {
				cursor: pointer;
				background-color: #eeeeee;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	&__report {
		height: 100%;
		> div {
			height: calc(100% - 33px);
		}
	}
	&__button {
		height: 33px;
		margin-right: 10px !important;
	}
}

.sol-analysis {
	@extend .analysis;

	&__print-container {
		@extend .analysis__print-container;

		&__comparison-title {
			@extend .analysis__print-container__comparison-title;
			color: $solLightBlack;
		}
	}
}

.analysis-feelis {
	&__container {
		max-height: 87vh;
	}
	&__report {
		height: 100%;
		> div {
			height: calc(100% - 33px);
		}
	}
}
