.flex-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: inherit;
}

.flex-container__row {
  display: flex;
  flex-direction: row;
}

.is-scrollable {
  overflow: auto;
}

.is-hidden {
  display: none;
}

.grid-container {
  display: grid;
  padding-left: 20px !important;
  padding-right: 20px !important;
  gap: 0 20px;
  grid-template-columns: 300px auto;
  grid-template-rows: auto;
  grid-template-areas: 'nav content';
}

.margin-both-20 {
  margin: 20px 0;
}

.element {
  &.is-loading {
    pointer-events: none;
    opacity: 0.7;

    &:after {
      @include loader;
      border-left-color: $iisyBlue;
      border-bottom-color: $iisyBlue;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }

  &.is-loading-relative {
    pointer-events: none;
    opacity: 0.7;
    display: block;
    margin: auto;

    &:after {
      @include loader;
      border-left-color: $iisyBlue;
      border-bottom-color: $iisyBlue;
      width: 4em;
      height: 4em;
      border-width: 0.25em;
    }
  }

  &.is-loading-absolute {
    position: absolute;
    pointer-events: none;
    opacity: 0.7;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &:after {
      @include loader;
      border-left-color: $iisyBlue;
      border-bottom-color: $iisyBlue;
      width: 4em;
      height: 4em;
      border-width: 0.25em;
    }
  }

  &.is-loading-absolute-list {
    @extend .element, .is-loading-absolute;
    top: 24.5%;
  }

  &.is-loading-small {
    pointer-events: none;
    opacity: 0.7;

    &:after {
      @include loader;
      border-left-color: $iisyBlue;
      border-bottom-color: $iisyBlue;
      position: relative;
      left: calc(50% - 1.25em);
      width: 2.5em;
      height: 2.5em;
      border-width: 0.15em;
    }
  }

  &.is-loading-small-relative {
    @extend .element, .is-loading-small;
    display: block;
    margin: auto;
  }
}

.spinner-error {
  &.is-loading {
    pointer-events: none;
    opacity: 0.7;


    border-left-color: $iisyBlue;
    border-bottom-color: $iisyBlue;
    position: absolute;
    top: calc(50% - 2.5em);
    left: calc(50% - 2.5em);
    width: 5em;
    height: 5em;
    border-width: 0.25em;

  }

  &.is-loading-relative {
    pointer-events: none;
    opacity: 0.7;
    display: block;
    margin: auto;

    border-left-color: $iisyBlue;
    border-bottom-color: $iisyBlue;
    width: 4em;
    height: 4em;
    border-width: 0.25em;

  }

  &.is-loading-absolute {
    position: absolute;
    pointer-events: none;
    opacity: 0.7;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-left-color: $iisyBlue;
    border-bottom-color: $iisyBlue;
    width: 4em;
    height: 4em;
    border-width: 0.25em;

  }

  &.is-loading-absolute-list {
    @extend .spinner-error, .is-loading-absolute;
    top: 24.5%;
  }

  &.is-loading-small {
    pointer-events: none;
    opacity: 0.7;

    border-left-color: $iisyBlue;
    border-bottom-color: $iisyBlue;
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border-width: 0.15em;

  }

  &.is-loading-small-relative {
    @extend .spinner-error, .is-loading-small;
    display: block;
    margin: auto;
  }
}


.min-width-0 {
  min-width: 0;
}

.rounded-button {
  font-size: 0.8rem !important;
  border-radius: 18px !important;
}

.mt-10 {
  margin-top: 10px;
}
