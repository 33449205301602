.organizationDiagram {
	width: 100%;
	height: calc(100vh - 350px);
	margin-top: 3rem;
	background: rgba(255, 255, 255, 0.3);
	border-radius: 20px;

	&__card {
		box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
		background-color: #fff;

		&--root {
			&__header {
				background-color: $darkBlue300;
			}
			&__title {
				color: #fff;
				text-align: center;
				line-height: 30px;
				font-size: 16px;
			}
		}

		&--branch {
			&__header {
				background-color: $titleLightBlue;
			}
			&__title {
				color: #fff;
				text-align: center;
				line-height: 30px;
				font-size: 16px;
			}
		}

		&--leaf {
			&__header {
				background-color: #fff;
				border: 1px solid rgb(0, 0, 0);
			}
			&__title {
				color: rgb(0, 0, 0);
				text-align: center;
				line-height: 30px;
				font-size: 16px;
			}
		}

		&__body {
			margin-top: 0.5rem;
			min-height: 64px;
			display: flex;
			flex-direction: column;
			margin-left: 0.8rem;

			&--row {
				&:is(:first-child) {
					margin-bottom: 0.5rem;
				}

				display: flex;
				align-items: center;
			}
			&__text {
				font-weight: 600;
			}
		}
	}
}

.sol-organizationDiagram {
	@extend .organizationDiagram;

	&__card {
		@extend .organizationDiagram__card;

		&--root {
			@extend .organizationDiagram__card--root;

			&__header {
				background-color: $solDarkYellow;
			}

			&__title {
				@extend .organizationDiagram__card--root__title;
				color: $solMenthol;
			}
		}

		&--branch {
			@extend .organizationDiagram__card--branch;

			&__header {
				background-color: $solOrange;
			}

			&__title {
				@extend .organizationDiagram__card--leaf__title;
				color: #fff;
			}
		}

		&--leaf {
			@extend .organizationDiagram__card--leaf;

			&__header {
				background-color: $solLightYellow;
				border: none;
			}

			&__title {
				@extend .organizationDiagram__card--leaf__title;
				color: $solMenthol;
			}
		}
	}
}
