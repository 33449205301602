.measurement-graph {
	&__title {
		color: $iisyBlue;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 0.6px;
		text-align: center;
	}

	&__no-data-title {
		color: $titleLightBlue;
		font-size: 16px;
		margin: 20px 0;
		text-align: center;
	}

	&__zoom-button {
		display: flex;
		align-items: center;
		height: 30px;
		margin-left: 45px;
	}

	&__min-max-avg {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		&__block {
			display: flex;
			align-items: center;
			margin-right: 20px;
		}

		&__status {
			display: block;
			height: 10px;
			width: 10px;
			border-radius: 50%;
			background-color: #fff;
			margin-right: 5px;
		}

		&__title {
			color: $titleLightBlue;
			font-size: 11px;
			font-weight: 700;
			margin-right: 10px;
		}

		&__value {
			color: #171a2b;
			font-size: 11px;
			font-weight: 500;
			margin-right: 10px;
		}

		&__premise {
			color: #171a2b;
			font-size: 12px;
			font-weight: 700;
		}
	}
}

.sol-measurement-graph {
	@extend .measurement-graph;

	&__title {
		@extend .measurement-graph__title;
		color: #262122;
	}

	&__min-max-avg {
		@extend .measurement-graph__min-max-avg;

		&__title {
			@extend .measurement-graph__min-max-avg__title;
			color: #262122;
		}
	}

	&__no-data-title {
		@extend .measurement-graph__no-data-title;
		color: #262122;
	}
}

.is-over-threshold {
	background-color: $iisyRed;
}

.measurement-graph-container {
	display: flex;
	flex-direction: column;
	grid-area: graph;

	&__inner {
		@extend .measurement-graph-container;
		//height: 100vh;
		//overflow: scroll;
	}
}

.measurement-graph-titles {
	background: #fff;
	border-radius: 10px;
	margin-bottom: 20px;
	height: 40px;

	&--text-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 40px;
		margin: 0 1rem;
	}

	&--type {
		margin-left: 1rem;
		font-size: 14px;
		color: $darkBlue200;

		&:first-of-type {
			margin-right: 3rem;
		}
	}

	&--mode {
		margin-left: 3rem;
		font-size: 14px;
		color: $darkBlue200;
	}

	&--print-only {
		display: none;
		margin-left: 3rem;
		font-size: 14px;
		color: $darkBlue200;
	}
}

.sol-measurement-graph-titles {
	@extend .measurement-graph-titles;

	&--type {
		@extend .measurement-graph-titles--type;
		color: #262122;
	}

	&--mode {
		@extend .measurement-graph-titles--mode;
		color: #262122;
	}

	&--print-only {
		@extend .measurement-graph-titles--print-only;
		color: #262122;
	}
}

.measurement-graph-card {
	background: #fff;
	border-radius: 10px;
	height: fit-content;
	margin-bottom: 20px;
	border: 1px solid $iisyBorder;

	&:last-of-type {
		margin-bottom: 0;
	}

	&__inner {
		height: calc(100% - 90px);
	}

	&__two {
		height: 50%;
	}

	&__one {
		height: 100%;
	}
}

.measurement-controls {
	grid-area: controls;
	border-radius: 10px;
	border: 1px solid $iisyBorder;

	&__print-button {
		height: 33px;
	}

	&__shortcuts {
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 500;
		color: #00195f;

		&--container {
			padding-bottom: 5px;
		}
	}

	&__choose {
		margin-bottom: 8px;
		font-size: 14px;
		color: $darkBlue200;
	}

	&__comparison {
		font-size: 18px;
		font-weight: 500;
		color: $iisyBlue;
		margin-bottom: 10px;
		text-align: center;

		&--container {
			padding-top: 10px;
		}
	}
}

.sol-measurement-controls {
	@extend .measurement-controls;

	&__shortcuts {
		@extend .measurement-controls__shortcuts;
		color: #262122;
	}

	&__choose {
		@extend .measurement-controls__choose;
		color: #262122;
	}

	&__comparison {
		@extend .measurement-controls__comparison;
		color: #262122;
	}
}

.recharts-wrapper {
	user-select: none;
}
