.big-score {
	display: flex;
	flex-direction: row;
	justify-content: center;

	&__column {
		display: flex;
		min-width: 120px;
		max-width: 140px;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&--long {
			max-width: 190px;
			width: 190px;
		}
	}

	&__container {
		width: 90px;
		height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__circle {
		&--inner {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: $iisyBlue;
			display: flex;
			align-items: center;
			justify-content: center;

			&__value {
				color: #fff;
				font-weight: 500;
				font-family: 'MiriamLibre';
				font-size: 24px;
			}
		}
	}

	&__points-title {
		min-height: 48px;
		margin-left: 15px;
		font-size: 14px;
		color: $iisyBlue;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.5px;

		&--small {
			max-width: 90%;
			font-size: 12px;
		}
	}

	&__change-title {
		width: 130px;
		padding: 5px 0;
		font-size: 12px;
		color: $iisyGreen;
		background-color: $iisyBlue;
		border-radius: 15px;
		text-align: center;

		&--small {
			max-width: 90%;
		}
	}
}

.sol-big-score {
	@extend .big-score;

	&__change-title {
		@extend .big-score__change-title;
		color: $solMenthol;
		background-color: rgba(187, 187, 187, 0.5);
	}

	&__points-title {
		@extend .big-score__points-title;
		color: $solLightBlack;
	}
}

.big-user-score {
	display: flex;
	flex-direction: row;

	&__column {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}

	&__points-title {
		font-size: 14px;
		color: $iisyBlue;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.5px;

		&--small {
			max-width: 90%;
			font-size: 12px;
		}
	}

	&__change-title {
		width: 100px;
		height: 25px;
		padding: 3px 0;
		font-weight: 700;
		color: $iisyBlue;
		border-radius: 15px;
		text-align: center;
	}
}
