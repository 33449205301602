.userForm {
	width: 70%;
	padding-left: 10px;
	height: 100%;

	&__inputGroup {
		&__row {
			display: flex;
			align-items: center;

			&--align {
				color: $titleLightBlue;
				justify-content: flex-end;
				height: 8%;
			}
		}

		&--select {
			width: 60%;
			margin-right: 1rem;
		}

		&--margin {
			margin-bottom: 18px;
		}

		&--shift {
			width: 96.5%;
		}
	}

	&__permissions {
		&__list {
			display: flex;
			flex-direction: column;

			&__row {
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				&:last-of-type {
					margin-bottom: 20px;
				}

				&__text {
					display: inline;
					font-size: 14px;
					margin-left: 5px;
				}
			}
		}
	}
}
