.notification {
	display: flex;
	flex-direction: column;

	&__title {
		height: 10%;
		&--text {
			text-align: center;
			font-size: 20px;
			color: $darkBlue;
		}
		&--divider {
			height: 2px;
			width: 500px;
			margin-top: 5px;
			margin-bottom: 1.2rem;
			background-color: #dbdce6;
		}
	}
	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 82%;

		&__text-block {
			text-align: center;
			font-weight: 300;
			font-size: 17px;
			line-height: 1.7rem;
			color: $darkBlue;
			padding: 0px 60px;
			margin-bottom: 1rem;

			&--2 {
				height: auto;
			}

			&--3 {
				height: 25%;
			}
		}

		&__image-block {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 1rem;
			overflow: hidden;
			height: 50%;
		}

		&__image {
			height: 100%;
			cursor: zoom-in;
		}
	}
	&__pagePagination {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;
		height: 5%;
	}

	&__request-size {
		margin-top: 1rem;
		margin-bottom: 1rem;

		&__value {
			color: #00195a;
			font-size: 18px;
			margin-left: 15px;
			border-bottom: 1px solid #00195a;
		}

		&__warning {
			color: #d32f2f;
			font-size: 12.3px;
		}
	}
}

.sol-notification {
	@extend .notification;

	&__title {
		@extend .notification__title;

		&--text {
			@extend .notification__title--text;
			color: $solLightBlack;
		}
	}

	&__content {
		@extend .notification__content;

		&__text-block {
			@extend .notification__content__text-block;
			color: $solLightBlack;

			&--2 {
				height: auto;
			}

			&--3 {
				height: 25%;
			}
		}
	}

	&__request-size {
		@extend .notification__request-size;

		&__value {
			@extend .notification__request-size__value;
			color: $solLightBlack;
		}
	}
}
