.control-panel {
	&__container {
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		&__header {
			margin-bottom: 1rem;
			display: flex;
			align-items: center;

			&__heading {
				color: #2a3886;
				font-size: 25px;
				text-transform: uppercase;
				letter-spacing: 2.2px;
				text-align: center;
				margin-right: 0.3rem;
			}
		}

		&__dataList {
			margin-top: 2rem;
			display: flex;
			width: 95%;
			@media (min-width: $largeScreenThreshold) {
				width: 70%;
			}
		}
	}
}

.sol-control-panel {
	@extend .control-panel;

	&__container {
		@extend .control-panel__container;

		&__header {
			@extend .control-panel__container__header;

			&__heading {
				@extend .control-panel__container__header__heading;
				color: $solLightBlack;
			}
		}
	}
}
