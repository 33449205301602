html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f6f7f8 !important;
  overflow-y: hidden !important;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7f8 !important;

  font-family: 'Poppins', Tahoma, Geneva, Verdana, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  background: #f6f7f8;
  overflow-y: hidden;
}

@media (-webkit-device-pixel-ratio: 2.75) and (min-width: 1366px) and (max-height: 1080px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}

@media (-webkit-device-pixel-ratio: 2.5) and (min-width: 1366px) and (max-height: 1080px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}

@media (-webkit-device-pixel-ratio: 2.25) and (min-width: 1366px) and (max-height: 1080px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}

@media (-webkit-device-pixel-ratio: 2) and (min-width: 1366px) and (max-height: 1080px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}

@media (-webkit-device-pixel-ratio: 1.75) and (min-width: 1280px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) and (min-width: 1280px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) and (min-width: 1280px) {
  .card {
    width: 275px;
  }
  .details__info__column__heading--small {
    font-size: 10px;
  }
  .details__info {
    margin: 20px;
  }
}
