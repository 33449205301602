.profile {
	display: flex;
	margin: 40px;
	justify-content: center;
	&__container {
		width: 800px;
	}
	&__form {
		width: 66%;
		&__columns {
			display: flex;
			flex-direction: row;
		}
	}
	&__card {
		background-color: #fff;
		margin-bottom: 40px;
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
		border: solid 1px #80889c3a;
		&:last-of-type {
			margin: 0;
		}
		&__content {
			display: flex;
			padding: 25px 40px;
			&--row {
				display: flex;
				align-items: center;
			}
			&--third {
				width: 33%;
			}
			&--half {
				width: 50%;
			}
			&--full {
				display: flex;
				flex-direction: column;
				width: 100%;
				align-items: center;
				gap: 20px;
			}
		}
		&__footer {
			height: 100%;
			display: flex;
			flex-direction: row;
			gap: 10px;
			justify-content: flex-end;
			align-items: flex-end;
		}
		&__name {
			font-size: 18px;
			color: $darkBlue200;
		}
		&__role {
			font-size: 16px;
			font-weight: 300;
			padding-top: 5px;
		}
		&__lesser-text {
			color: #4e4e4e;
			font-size: 14px;
			font-weight: 300;
			padding-top: 5px;
		}
		&__title {
			display: flex;
			align-items: center;
			font-size: 18px;
			color: $darkBlue;
			letter-spacing: 0.5px;
			height: 60px;
			padding-left: 40px;
			&--centered {
				@extend .profile__card__title;
				justify-content: center;
				padding: 0;
			}
		}
	}
}
