.list-pagination {
	padding: 0 8px;
	display: flex;
	align-items: center;
	border-radius: 18px;
	height: 35px;
	margin-top: 5px;

	> .pagination {
		width: 100%;
	}
}

.sol-list-pagination {
	@extend .list-pagination;
	margin-top: 0px;
}
