.portfolio {
  display: flex;
  align-items: center;
  height: 115px;
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  border: solid 1px #80889c3a;
  border-radius: 20px;
  gap: 5%;

  &__title {
    color: $iisyBlue;
    font-size: 19px;
    margin-top: 4px;
    margin-left: 20px;
  }

  &__org {
    margin-left: 20px;
    height: 30px;
    position: relative;

    > .org_name {
      color: $iisyBlue;
      font-weight: 700;
      font-size: 18px;
      max-width: 400px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--scroll {
      transform: translateX(0);
      transition: 1.25s;
    }

    &:hover .portfolio__org--scroll {
      transform: translateX(calc(200px - 100%));
    }
  }

  &__container {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    &--title {
      width: fit-content;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $mobileThreshold) {
      flex-wrap: wrap;
      width: 100%;
      gap: 10px;
    }
  }

  &__graph {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 35%;

    &--fullHd {
      max-width: 25%;
    }
  }

  @media (max-width: $mobileThreshold) {
    flex-direction: column;
    height: auto;
    margin-top: 10px;
  }
  @media (max-width: $mobileThreshold) {
    padding: 10px;
  }
}

.sol-portfolio {
  @extend .portfolio;

  &__title {
    @extend .portfolio__title;
    color: $solLightBlack;
  }

  &__org {
    @extend .portfolio__org;

    > span {
      color: $solLightBlack;
    }
  }
}
