.details {
	&__flavor {
		display: flex;
		justify-content: center;

		&__title {
			text-transform: uppercase;
			letter-spacing: 1.2px;
		}
	}

	&__info {
		display: flex;
		margin: 30px;

		&__container {
			margin-top: 2rem;
		}

		&__row {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			&:last-of-type {
				margin: 0;
			}

			&--modal {
				@extend .details__info__row;
				margin-bottom: 50px;
			}
		}

		&__column {
			display: flex;
			flex-direction: column;
			width: 480px;
			padding-right: 10px;

			&__heading {
				display: flex;
				align-items: center;
				height: 20px;

				&--small {
					font-size: 12px;
					height: 35px;
				}
			}

			&--large {
				display: flex;
				flex-direction: column;
				padding-right: 10px;

				&--right {
					@extend .details__info__column--large;
					padding-right: 0;
					padding-left: 30px;
					@media (max-width: $mobileThreshold) {
						padding-left: 0;
					}
				}
			}

			&--modal {
				@extend .details__info__column;
				width: 25%;
				align-items: center;
			}
		}

		&__left {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin: 20px 0;
			width: 48%;
			gap: 20px;

			@media (max-width: $mobileThreshold) {
				width: auto;
			}
		}

		&__right {
			display: flex;
			flex-direction: column;
			width: 52%;
			margin: 20px 0;
			@media (max-width: $mobileThreshold) {
				width: auto;
			}

			&--column {
				&:not(:first-child) {
					margin-left: 2em;
				}
			}
		}

		&__title {
			font-size: 20px;
			color: $darkBlue200;

			&--small {
				font-size: 14px;
				color: $iisyBlue;
				font-weight: 500;
			}
		}
	}
}

.sol-details {
	@extend .details;

	&__info {
		@extend .details__info;

		&__title {
			@extend .details__info__title;
			color: $solLightBlack;

			&--small {
				@extend .details__info__title--small;
				color: $solLightBlack;
			}
		}
	}
}

@media screen and (max-width: 2000px) {
	.details__info__row {
		flex-wrap: wrap;
		gap: 20px;
		max-width: 100%;
	}
	.details__info__column {
		flex: 0 0;

		@media (min-width: $mobileThreshold) {
			flex-basis: calc(50% - 10px);
		}
		// this will be modified once refactor modal
		&--modal {
			@extend .details__info__column;
			align-items: center;
		}
	}

	.details__info__column__heading--small {
		height: 20px;
	}

	.details__info__right {
		&--column:nth-child(1) {
			margin-left: 2em;
		}
	}
}
