.userModal {
  height: 550px;

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 84.5%;

    &--edit {
      flex-direction: row;
      justify-content: space-around;
      position: relative;
      height: 100%;
      margin: 0rem 2rem;
      padding: 10px 0px;
    }

    &--create {
      flex-direction: row;
      justify-content: space-around;
      height: 90.5%;
      margin: 2rem 2rem;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      height: 80%;

      &--edit {
        justify-content: left;
        position: absolute;
        left: 0px;
        width: 70%;
        height: 100%;
      }
    }

    &__infoColumn {
      display: flex;
      flex-direction: column;
      height: 'auto';
      justify-content: flex-start;

      &:last-child {
        justify-content: flex-start;
        height: 84.5%;
      }
    }

    &__infoBlock {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 3rem;
      }

      @media (max-width: 1250px) {
        margin-bottom: 10px !important;
      }

      &__row {
        display: flex;
        flex-direction: row;
        font-size: 14px;
      }

      &__icon {
        color: $titleLightBlue;
      }

      &__title {
        margin-left: 0.5rem;
        color: $titleLightBlue;
        font-size: 16px;
      }
    }

    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 35%;
      top: 25%;
      height: auto;
      right: 5%;

      &--wide {
        width: 60.5%;
      }

      &--inspect {
        width: 100%;
      }
    }

    &__buildingHeader {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      &__title {
        color: #9c4bec;
        margin-left: 0.5rem;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__divider {
      width: 100%;
      height: 3px;
      background: linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 1) 0%,
                      #9c4bec 20%,
                      #9c4bec 80%,
                      rgba(255, 255, 255, 1) 100%
      );
    }

    &__buildingListRow {
      height: 40px;

      &:nth-child(even) {
        background: rgba(138, 60, 227, 0.07);
      }
    }

    &__placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem;
      width: 100%;
      height: 250px;

      &__text {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 1rem;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
        text-align: center;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 15.5%;
    background: $blueGrey;
  }
}

.sol-userModal {
  @extend .userModal;

  &__body {
    @extend .userModal__body;

    &__divider {
      @extend .userModal__body__divider;
      background: linear-gradient(
                      90deg,
                      rgba(255, 255, 255, 1) 0%,
                      #005f67 20%,
                      #005f67 80%,
                      rgba(255, 255, 255, 1) 100%
      );
    }

    &__infoBlock {
      @extend .userModal__body__infoBlock;

      &__icon {
        color: $solLightBlack;
      }

      &__title {
        @extend .userModal__body__infoBlock__title;
        color: $solLightBlack;
      }
    }

    &__buildingHeader {
      @extend .userModal__body__buildingHeader;

      &__title {
        @extend .userModal__body__buildingHeader__title;
        color: $solMenthol;
      }
    }

    &__buildingListRow {
      @extend .userModal__body__buildingListRow;

      &:nth-child(even) {
        background: rgba(252, 235, 194, 0.7);
      }
    }
  }
}
