.card {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 310px;
	background: var(--brand-card-backgroundColor);
	box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
	border: solid 1px #80889c3a;
	border-radius: 30px;
	padding-top: 15px;
	transition: 0.25s ease color;
	transition: 0.25s ease background;

	&__type {
		color: var(--brand-card-textColorType);
		font-size: 12px;
		padding: 0 25px;
	}

	&__title {
		width: calc(100% - 13px);
		height: 30px;
		position: relative;
		overflow: hidden;

		> h3 {
			margin: 0;
			color: var(--brand-card-textColorTitle);
			font-size: 16px;
			padding: 0 25px;
			position: absolute;
			white-space: nowrap;
		}

		&--scroll {
			transform: translateX(0);
			transition: transform 1.0s;
		}

		&:hover .card__title--scroll {
			transform: translateX(calc(310px - 100%));
		}
	}

	&__scores {
		display: flex;
		flex-direction: column;
		height: 235px;
		padding: 0.4rem 25px;

		.user-score__points-title,
		.score__points-title {
			color: var(--brand-card-textColorTitleScorePoints);
		}

		.user-score__change-value,
		.score__change-title {
			color: var(--brand-card-textColorTitleChange);
		}

		#score-arrow-up {
			color: var(--brand-card-scoreArrowUp);
		}
	
		#score-change-value {
			color: var(--brand-card-scoreChangeValue);
		}
	
		.user-score__value--good {
			color: var(--brand-card-userScoreGood);
		}
	
		.user-score__value--negative {
			color: var(--brand-card-userScoreBad);
		}
	
		.user-score__value--ok {
			color: var(--brand-card-userScoreOk);
		}

	}

	&__insights {
		display: flex;
		width: 100%;
		height: 50px;
		align-items: center;
		justify-content: space-between;
		&__title {
			font-size: 12px;
			color: var(--brand-card-footerColorText);
		}
		&__amount {
			padding-left: 5px;
			font-size: 16px;
			font-family: 'MiriamLibre', sans-serif;
			font-weight: 500;
			color: var(--brand-card-footerColorText);
		}
	}

	&__footer {
		width: 100%;
		padding: 0 25px;
		color: var(--brand-card-footerColorText);
		background-color: var(--brand-card-footerBackgroundColor);
		border-top: 1px solid var(--brand-card-footerBorderTop);
	}

	&:hover {
		cursor: pointer;
		background: var(--brand-card-backgroundColorHovered);
		.card__type {
			color: var(--brand-card-textColorTypeHovered);
		}

		.card__title > h3 {
			color: var(--brand-card-textColorTitleHovered);
		}

		.user-score__points-title,
		.score__points-title {
			color: var(--brand-card-textColorTitleScorePointsHovered);
		}

		.user-score__change-value,
		.score__change-title {
			color: var(--brand-card-textColorTitleChangeHovered);
		}

		.card__insights__title {
			color: var(--brand-card-footerColorTextHovered);
		}
		.card__insights__amount {
			color: var(--brand-card-footerColorTextHovered);
		}

		#score-arrow-up {
			color: var(--brand-card-scoreArrowUpHovered);
		}
	
		#score-change-value {
			color: var(--brand-card-scoreChangeValueHovered);
		}

		.user-score__value--good {
			color: var(--brand-card-userScoreGoodHovered);
		}
	
		.user-score__value--negative {
			color: var(--brand-card-userScoreBadHovered);
		}
	
		.user-score__value--ok {
			color: var(--brand-card-userScoreOkHovered);
		}
		
		.user-score__icon {
			filter: invert(98%) sepia(77%) saturate(0%) hue-rotate(256deg) brightness(111%) contrast(100%);
		}
		.user-score__icon--negative {
			filter: invert(37%) sepia(100%) saturate(6540%) hue-rotate(340deg) brightness(87%) contrast(92%);
		}
		.user-score__icon--ok {
			filter: invert(86%) sepia(55%) saturate(472%) hue-rotate(341deg) brightness(106%) contrast(94%);
		}
		.user-score__icon--good {
			filter: invert(71%) sepia(66%) saturate(324%) hue-rotate(106deg) brightness(101%) contrast(102%);
		}
		.card__footer {
			background: var(--brand-card-footerBackgroundColorHovered);
			border-bottom-left-radius: 30px;
			border-bottom-right-radius: 30px;
		}
	}
}
