.eventLog {
    &__header {
        display: flex;

        
        &__title {
            color: #61668B;
            text-transform: uppercase;
            font-size: .9rem;
            letter-spacing: 1px;
        }
    }
}