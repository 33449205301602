.pdf-container {
	border-radius: 10px;
	background: #fff;
	padding: 20px;
	width: inherit;
	height: calc(100vh - 172px);

	&__noData {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&__placeholderText {
		color: rgba(84, 84, 84, 0.7);
		font-size: 18px;
	}

	&__document {
		height: calc(100vh - 230px);
		width: 100%;
	}
	&__pagination {
		display: flex;
		align-items: center;
		margin-top: 10px;
		&--numbers {
			font-weight: 600;
			margin: 0 15px;
		}
	}
}
